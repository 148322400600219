import {StoryblokComponent} from '@storyblok/react'
import classNames from 'classnames'
import React, {ReactNode} from 'react'
import {SectionStoryblok} from 'types/storyblok-types'
import BackgroundMapper from 'util/BackgroundMapper'
import HeaderContainer from './Header'
import CtaContainer from './Cta'
import TextBlockContainer from './Text'
import {CachedServiceDeliveryPoint} from 'types/types'

interface SectionPropsType {
  blok: Pick<SectionStoryblok, 'content' | 'background_color' | '_uid'>
  sectionAfterRoundedHero: boolean
  noTopMargin: boolean
  customTopMargin?: number
  children?: ReactNode
  sdpl?: CachedServiceDeliveryPoint
}

const Section = ({
  blok,
  sectionAfterRoundedHero,
  noTopMargin,
  customTopMargin,
  sdpl,
  children,
}: SectionPropsType) => {
  const sectionBackgroundColor =
    blok.background_color.length > 0
      ? blok.background_color?.[0].background_color
      : 'white'
  const backgroundColorClass = blok.background_color
    ? BackgroundMapper(sectionBackgroundColor)
    : BackgroundMapper('white')
  const sectionAfterRoundedHeroClass = classNames({
    'rounded-t-dats-xl md:rounded-t-[50px] z-20 relative':
      sectionAfterRoundedHero,
  })

  const topMarginClass = customTopMargin
    ? `mt-[${customTopMargin}px]`
    : classNames({
        'mt-[100px]':
          !noTopMargin && !sectionAfterRoundedHero && !customTopMargin,
        'mt-[0px]':
          (sectionAfterRoundedHero || noTopMargin) && !customTopMargin,
      })

  return (
    <section
      className={`${topMarginClass} flex w-full justify-center px-5 lg:px-[100px] ${backgroundColorClass} ${sectionAfterRoundedHeroClass}`}
    >
      <div
        className={`grid w-full max-w-[1366px] ${
          sectionAfterRoundedHero ? 'mt-[44px]' : 'mt-0'
        }`}
      >
        {blok.content
          ? blok.content.map((blok, idx) => {
              switch (blok.component) {
                case 'header':
                  if (idx > 0) {
                    return (
                      <div className="mt-8" key={blok._uid}>
                        <HeaderContainer
                          blok={blok}
                          withoutBottomMargin={true}
                          sdpl={sdpl}
                        />
                      </div>
                    )
                  }
                  return (
                    <HeaderContainer
                      key={blok._uid}
                      blok={blok}
                      withoutBottomMargin={true}
                      sdpl={sdpl}
                    />
                  )

                case 'text':
                  return (
                    <TextBlockContainer
                      key={blok._uid}
                      blok={blok}
                      topMargin={8}
                      sdpl={sdpl}
                    />
                  )

                case 'cta_block':
                  if (sectionBackgroundColor === 'light grey') {
                    // Don't apply left margins
                    return idx > 0 ? (
                      <div
                        className="mt-10 grid overflow-hidden md:overflow-auto"
                        key={blok._uid}
                      >
                        <CtaContainer blok={blok} noLeftTextMargin={true} />
                      </div>
                    ) : (
                      <CtaContainer
                        key={blok._uid}
                        blok={blok}
                        noLeftTextMargin={true}
                      />
                    )
                  } else {
                    // If a different background color was selected we assume the background
                    // spans the width of the page, in which case we don't want the text to contain
                    // an extra left margin.
                    return idx > 0 ? (
                      <div
                        className="mt-10 grid overflow-hidden md:overflow-auto"
                        key={blok._uid}
                      >
                        <CtaContainer blok={blok} noLeftTextMargin={false} />
                      </div>
                    ) : (
                      <CtaContainer
                        key={blok._uid}
                        blok={blok}
                        noLeftTextMargin={false}
                      />
                    )
                  }
                default:
                  if (idx > 0) {
                    return (
                      <div className="mt-10 grid" key={blok._uid}>
                        <StoryblokComponent blok={blok} />
                      </div>
                    )
                  }
                  return <StoryblokComponent key={blok._uid} blok={blok} />
              }
            })
          : null}
        {children}
      </div>
    </section>
  )
}

export default Section
